<template>
  <div>
    <bread-crumb v-if="pageId" :crumbData="['水票管理', '编辑']" :chooseCrumbData="2" @close="onClose"></bread-crumb>
    <bread-crumb v-else :crumbData="['水票管理', '新增']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="水票类型：" prop="water_type">
        <el-select v-model="ruleForm.water_type" placeholder="请选择">
          <el-option v-for="(item, index) in water_types" :key="index" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="水票名称：" prop="wt_name">
        <el-input v-model="ruleForm.wt_name"></el-input>
      </el-form-item>
      <el-form-item label="适用商品：">
        <el-table ref="table" :data="tableData" tooltip-effect="dark" border style="width: 100%">
          <el-table-column label="图片">
            <template slot-scope="scope">
              <img :src="`http://txx.com${scope.row.thumbnail}`" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="product_name" label="名称"></el-table-column>
          <el-table-column prop="specifications" sortable label="规格"></el-table-column>
          <el-table-column prop="unit" label="单位"></el-table-column>
          <el-table-column prop="market_price" sortable label="市场价"></el-table-column>
          <el-table-column prop="sales_price" sortable label="销售价"></el-table-column>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleDelete(scope.$index)">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_delete"></span>
                </div>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="default" @click="dialogVisible = true">添加商品</el-button>
      </el-form-item>
      <el-form-item label="水票启用：" prop="isEnable">
        <el-switch v-model="ruleForm.isEnable" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{
          pageId == 0 ? "新增" : "修改"
        }}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="商品档案" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <dialog-commodity-file @productDetailData="setData"></dialog-commodity-file>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["pageId"],
  components: {
    dialogCommodityFile: () => import("./dialogCommodityFile.vue"),
  },
  data() {
    return {
      ruleForm: {
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        wt_name: "",
        water_type: "",
        isEnable: "",
        pid: "",
      },
      rules: {
        wt_name: [
          { required: true, message: "请输入水票名称", trigger: "blur" },
        ],
        water_type: [{ required: true, message: "请选择", trigger: "change" }],
      },

      tableData: [],

      water_types: ["水票", "自提水票"],

      dialogVisible: false,
      pidArr: [],
    };
  },
  mounted() {
    if (this.pageId != 0) {
      this.getPageInfo();
    }
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    getPageInfo() {
      this.$http
        .post(
          "/water_ticket/detail",
          this.$qs.stringify({
            id: this.pageId,
          })
        )
        .then((res) => {
          this.ruleForm = res.data;
          const idArr = this.ruleForm.pid.split(",");
          this.$http
            .post(
              "/product/product_detail",
              this.$qs.stringify({
                id: idArr,
              })
            )
            .then((res) => {
              this.tableData = res.data;
            });
        });
    },

    // 表格关闭
    handleClose(done) {
      done();
    },
    // 表格的数据
    setData(data) {
      this.tableData = data;

      this.dialogVisible = false;
    },
    // 表格 删除行数据
    handleDelete(index) {
      this.tableData.splice(index, 1);
    },

    // 提交表单
    submitForm(formName) {
      const pidArr = this.tableData.map((item) => {
        return item.id;
      });
      this.ruleForm.pid = pidArr.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.pageId == 0) {
            this.$http.post(
              "/water_ticket/save",
              this.$qs.stringify({
                data: this.ruleForm,
              })
            );
          } else {
            this.$http.post(
              "/water_ticket/save",
              this.$qs.stringify({
                id: this.pageId,
                data: this.ruleForm,
              })
            );
          }
          this.onClose(0);
        } else {
          this.$message.error("遗漏必填字段填写，请重新填写!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.demo-ruleForm {
  width: 90%;
  padding: 30px;
}
</style>
